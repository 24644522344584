import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Image, useWindowDimensions } from 'react-native';
import { Col, Row, useDynamicResponsiveValue, Text, Scroll, Img } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { ASSETS, ICON, DEVICE_INFO, COLOR, fSize, STYLES } from 'const';
import { Footer, Header, LiquidEffect, StaticCask, Card, TableWithAction } from "components"
import Store from 'store';
import { VarHelper } from 'helpers';
import { AntDesign } from '@expo/vector-icons';

import { useCommission } from './Casks.useCommission';

const Casks: IScreen = () => {
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const [{ listClients, listAvailableCasks }, cApi] = Store.Client.createStore();
  const [{ email }] = Store.User.createStore();
  const [showAvailableCask, setShowAvailableCask] = useState(false);
  const [{ }, mApi] = Store.Message.createStore();

  const {
    portfolioValue,
    myCommision,
    nextCommision,
    myIndex,
    comission,
  } = useCommission();

  const { caskHeight, caskWidth, spacer, text } = (() => {
    
    if (width >= 1000) {
      if (height >= 1080) return {
        caskHeight: height * 0.25, caskWidth: height * 0.25 * 577 / 740,
        spacer: { high: height * 0.04, medium: height * 0.05, bottom: 30, },
        text: { high: height * 0.04, medium: height * 0.05, bottom: 30, },
      }
      if (height >= 950) return {
        caskHeight: height * 0.25, caskWidth: height * 0.25 * 577 / 740,
        spacer: { high: height * 0.04, medium: height * 0.05, bottom: 30 },
        text: { high: height * 0.04, medium: height * 0.05, bottom: 30 },
      }
      if (height >= 900) return {
        caskHeight: height * 0.25, caskWidth: height * 0.25 * 577 / 740,
        spacer: { high: height * 0.03, medium: height * 0.05, bottom: 30 },
        text: { high: height * 0.03, medium: height * 0.05, bottom: 30 },
      }
      if (height >= 800) return {
        caskHeight: height * 0.25, caskWidth: height * 0.25 * 577 / 740,
        spacer: { high: height * 0.03, medium: height * 0.05, bottom: 20 },
        text: { high: height * 0.03, medium: height * 0.05, bottom: 20 },
      }
      if (height >= 768) return {
        caskHeight: height * 0.25, caskWidth: height * 0.25 * 577 / 740,
        spacer: { high: height * 0.02, medium: height * 0.05, bottom: 20 },
        text: { high: height * 0.02, medium: height * 0.05, bottom: 20 },
      }
      if (height >= 650) return {
        caskHeight: height * 0.25, caskWidth: height * 0.25 * 577 / 740,
        spacer: { high: height * 0.015, medium: height * 0.05, bottom: 10 },
        text: { high: height * 0.04, medium: height * 0.05, bottom: 10 },
      }
    }

    else if (width < 1000) {
      if (width >= 700) return {
        caskHeight: height < 700 ? height * 0.15 * 740 / 577 : width * 0.15 * 740 / 577, caskWidth: height < 700 ? height * 0.15 : width * 0.15,
        spacer: { high: height < 700 ? height * 0.05 : height * 0.35, medium: height < 700 ? height * 0.05 : height * 0.08, bottom: 10 },
        text: { high: height < 700 ? height * 0.05 : height * 0.35, medium: height < 700 ? height * 0.05 : height * 0.07, bottom: 10 },
      }
      if (width <= 480) return {
        caskHeight: width * 0.3 * 740 / 577, caskWidth: width * 0.3,
        spacer: { high: height < 700 ? height * 0.07 : height * 0.085, medium: height < 700 ? height * 0.07 : height * 0.055, bottom: 10 },
        text: { high: height < 700 ? height * 0.05 : height * 0.05, medium: height < 700 ? height * 0.05 : height * 0.035, bottom: 10 },
      }
      if (width <= 370) return {
        caskHeight: width * 0.3 * 740 / 577, caskWidth: width * 0.3,
        spacer: { high: height < 600 ? height * 0.07 : height * 0.09, medium: height < 700 ? height * 0.07 : height * 0.055, bottom: 10 },
        text: { high: height < 600 ? height * 0.05 : height * 0.05, medium: height < 700 ? height * 0.05 : height * 0.035, bottom: 10 },
      }
    }
    
    return {
      caskHeight: height * 0.15, caskWidth: height * 0.15 * 577 / 740,
      spacer: { high: 10, medium: 5, bottom: 10 },
      text: { high: 10, medium: 5, bottom: 10 },
    }
  })();

  const [activeIndex, setActiveIndex] = useState(myIndex);

  useEffect(() => {
    setActiveIndex(myIndex);
  }, [myIndex]);
  
  const myAM = listClients.length > 0 ? listClients[0]['Account Manager'] : '';

  const messageAdmin = async (item) => {
    // { Region: 'Highlands', Cask Type: 'Barel', Age: 5, Price: '5-10' }
    const [res, err] = await mApi.sendMessage({
      subject: 'Request cask info',
      from: email,
      message: `${name} (${email}) has requested info about this cask: Region ${item.Region} | type ${item['Cask Type']} | Age ${item.Age} | Price ${item.Price}`,
      am: myAM,
    });
    alert('Thank you, your request has been sent');
  }

  const renderSingleCask = () => {
    
    if (listClients.length === 0 || !email) return null;

    const targetPercentage = myCommision.range[1] === Infinity ? 1 : (portfolioValue - myCommision.range[0]) / (myCommision.range[1] - myCommision.range[0])

    const ratio = 1.8;
    const thisCaskWidth = caskWidth * ratio;
    const thisCaskHeight = caskHeight * ratio;

    return (
      <Row alignItems="flex-end" justifyContent='center'>
        <Col middle>
          <Col margin20 width={thisCaskWidth} height={thisCaskHeight}>
            <LiquidEffect
              key={`Casks-cask-${targetPercentage}`}
              width={thisCaskWidth} height={thisCaskHeight} mask={ASSETS.ONECASK}
              percent={targetPercentage}
            />
          </Col>
        </Col>
      </Row>
    );
  }

  const renderMultipleCasks = () => {
    if (listClients.length === 0 || !email) return null;
    const targetPercentage = myCommision.range[1] === Infinity ? 1 : (portfolioValue - myCommision.range[0]) / (myCommision.range[1] - myCommision.range[0])
    return (
      <Row onPress={() => {}} style={{ flexWrap: 'wrap' }} key={'percent-' + targetPercentage} alignItems="flex-end">
        {comission.map((val, i) => {
          const ratio = [0.7, 0.85, 1, 1.15][i];
          const thisCaskWidth = caskWidth * ratio;
          const thisCaskHeight = caskHeight * ratio;
          const circleSize = rV({ xs: 30, md: 70 });
          return (
            <Col middle marginHorizontal={rV({ xs: 0, md: 10 })} key={'cask-' + i}>
              <Col marginTop20 width={circleSize} height={circleSize} borderRadius={circleSize / 2} backgroundColor={"#A37A49"} middle>
                <Text colorWhite bold fontSize={rV({ xs: 10, md: 20 })}>{val.displayValue}%</Text>
              </Col>
              <Text colorWhite marginTop10 bold fontSize={rV({ xs: 10, md: 20 })}>{val.name.toUpperCase()}</Text>
              {width > 480 && (
                !Boolean(val.range[1] > 1000000000000000000) ? (
                  <Col marginTop10><Text bold colorWhite>{VarHelper.money(val.range[0])} - {VarHelper.money(val.range[1])}</Text></Col>
                ) : (
                  <Col marginTop10><Text bold colorWhite>{VarHelper.money(val.range[0])} +</Text></Col>
                )
              )}
              <Col margin20 width={thisCaskWidth} height={thisCaskHeight}>
                {i === myIndex ? (
                  <LiquidEffect
                    width={thisCaskWidth} height={thisCaskHeight} mask={ASSETS.ONECASK}
                    percent={targetPercentage}
                  />
                ) : (
                  <StaticCask
                    width={thisCaskWidth} height={thisCaskHeight} mask={ASSETS.ONECASK}
                    percent={i < myIndex ? 1 : 0}
                  />
                )}
              </Col>
            </Col>
          );
        })}
      </Row>
    );
  }

  const renderCaskCommissions = () => {
    if (listClients.length === 0 || !email) return null;
    const targetPercentage =
      myCommision.range[1] === Infinity
        ? 1
        : (portfolioValue - myCommision.range[0]) /
          (myCommision.range[1] - myCommision.range[0]);
    const ratio = Math.max(0.5, spacer.medium * 0.018);
    return (
      <Row
        width={rV({ xs: "calc(100% - 20px)", sm: "45%" })}
        minWidth={rV({ xs: 350, sm: 800 })}
        marginLeft={rV({ xs: 0, lg: 80 })}
        marginTop={rV({ xs: spacer.high, md: 0 })}
        marginBottom={rV({ xs: 30, md: 0 })}
        style={{ flexDirection: "column" }}
      >
        {comission.map((val, i) => {
          return (
            <Col
              middle
              width100p
              onPress={() => setActiveIndex(i === activeIndex ? -1 : i)}
              marginHorizontal={rV({ xs: 0, md: 10 })}
              key={"cask-" + i}
              padding={24 * ratio * 0.5}
              paddingLeft0
              style={{
                background:
                  activeIndex === i
                    ? "linear-gradient(180deg, #CA662E 0%, #752A00 100%)"
                    : "linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%)",
                border: "2.42px solid #FFFFFF4D",
              }}
            >
              <Row width100p 
              alignItems={'flex-start'} justifyContent={"space-between"}>
                <Col
                  width={rV({ xs: 40, md: 92 })}
                  marginTop={rV({ xs: 0, md: 10 })}
                  height50
                  borderRightWidth={0}
                  borderColor={COLOR.MAIN}
                  paddingHorizontal={5}
                  middle
                >
                  <Col width={30} height={30} middle>
                    <AntDesign
                      name={activeIndex === i ? "up" : "down" }
                      size={16}
                      color="white"
                    />
                  </Col>
                </Col>
                <Col width={rV({ xs: "calc(100% - 40px)", md: "calc(100% - 92px)" })}>
                  <Row width100p justifyContent={"space-between"}>
                    <Col>
                      <Text 
                        colorWhite 
                        fontFamily="PlayfairDisplay_600SemiBold" 
                        marginBottom={5} 
                        fontSize={Math.max(10, rV({ xs: 20 * ratio, md: 30 * ratio }))}
                        lineHeight={Math.max(15, rV({ xs: 30 * ratio, md: 40 * ratio }))}
                      >
                        {val.name.toUpperCase()}
                      </Text>
                      {!Boolean(val.range[1] > 1000000000000000000) ? (
                          <Col marginTop={-5}>
                            <Text bold colorWhite fontSize={rV({ xs: 16 * ratio, md: 23 * ratio })} lineHeight={rV({ xs: 23 * ratio, md: 30 * ratio })}>
                              {VarHelper.money(val.range[0])} -{" "}
                              {VarHelper.money(val.range[1])}
                            </Text>
                          </Col>
                        ) : (
                          <Col marginTop={-5}>
                            <Text bold colorWhite fontSize={rV({ xs: 16 * ratio, md: 23 * ratio })} lineHeight={rV({ xs: 23 * ratio, md: 30 * ratio })}>
                              {VarHelper.money(val.range[0])} +
                            </Text>
                          </Col>
                        )}
                    </Col>
                    <Row>
                      {i === myIndex && (
                        <Col
                          borderWidth={1}
                          borderColor={"white"}
                          width={rV({ xs: 80 * ratio, md: 119 * ratio })}
                          height={rV({ xs: 45 * ratio, md: 64 * ratio })}
                          backgroundColor={"rgba(173, 133, 85, 0.2)"}
                          middle
                        >
                          <Text
                            colorWhite
                            bold
                            fontSize={rV({ xs: 15 * ratio, md: 20 * ratio })}
                          >
                            Your Tier
                          </Text>
                        </Col>
                      )}
                      <Col
                        marginLeft={rV({ xs: 10 * ratio, md: 17})}
                        borderWidth={1}
                        borderColor={"white"}
                        width={rV({ xs: 60 * ratio, md: 119 * ratio })}
                        height={rV({ xs: 45 * ratio, md: 64 * ratio })}
                        backgroundColor={"#AD8555"}
                        middle
                      >
                        <Text colorWhite bold fontSize={rV({ xs: 15 * ratio, md: 20 * ratio })}>
                          {val.displayValue}%
                        </Text>
                      </Col>
                    </Row>
                  </Row>

                  {/* {activeIndex === i && (
                    <Text marginTop={25 * ratio * 0.6} fontSize={rV({ xs: 14 * ratio, md: 14 })} color={"white"}>
                      Lorem ipsum dolor sit amet consectetur. Neque arcu morbi
                      egestas venenatis volutpat nibh. Ultricies aliquam neque
                      sed dictum tortor vitae neque auctor. Senectus turpis
                      augue posuere purus ac adipiscing sed at. Dignissim arcu
                      sapien mi duis tincidunt ac erat libero varius.
                    </Text>
                  )} */}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    );
  };

  const modalSize = Math.min(width, height) * 0.9;

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <>
        <Header />
        <Col style={{ flex: 1 }}>
          <Col flex1>
            <Col
              absoluteFill
              alignItems="center"
              paddingBottom30
              backgroundColor="rgba(0,0,0,0.1)"
            >
              <Col absoluteFill zIndex={-1}>
                {/* <Img source={ASSETS.CASK_BG} style={STYLES.all100p} imageProps={{ resizeMode:"cover" }} /> */}
                <img
                  src={ASSETS.CASK_BG}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "bottom",
                  }}
                />
              </Col>
              <Text fontFamily="PlayfairDisplay_600SemiBold" colorWhite fontSize={text.high * 1.2} marginTop={spacer.high * 1.4} center>
                Investment Tier
              </Text>
              <Col
                width50
                height={1}
                bgWhite
                alignSelf="center"
                marginTop={5}
              />
              <Text bold colorWhite fontSize={text.high * 1.3} marginBottom={spacer.high * 1.4} marginTop={5} center>
                {VarHelper.money(portfolioValue)}
              </Text>
              {/* <Text colorWhite marginTop5 body1 center>When you trade more with Vintage Acquisitions we will reduce the backend commission.</Text> */}
              {/* {!!nextCommision && (
                <Text backgroundColor="black" center paddingVertical5 paddingHorizontal10 colorWhite marginTop5 body1>You are curently <Text colorWhite bold fontSize25>{VarHelper.money(nextCommision.range[0] - portfolioValue)}</Text> away from reaching '{nextCommision.name.toUpperCase()} STATUS'</Text>
              )} */}
              <Row width={'100%'} style={{flexWrap: 'wrap',}} justifyContent={'center'}>
                {useMemo(() => {
                  return (
                    <>
                      {renderSingleCask()}
                      {renderCaskCommissions()}
                    </>
                  );
                }, [listClients, activeIndex])}
              </Row>
              {/* <Col
                marginTop={spacer.bottom}
                alignSelf="center" bgMain width300 paddingVertical5 middle
                onPress={() => setShowAvailableCask(true)}
              >
                <Text colorWhite body1>CLICK TO SEE AVAILABLE CASKS</Text>
              </Col> */}
            </Col>

            {showAvailableCask && (
              <Col
                absolute
                top="50%"
                left="50%"
                transform={[
                  { translateX: -modalSize * 0.5 },
                  { translateY: -modalSize * 0.3 },
                ]}
                width={modalSize}
                height={modalSize / 2}
              >
                <Scroll height={modalSize / 2}>
                  <Card
                    title={"Available Casks"}
                    marginBottom20
                    iconRigth={
                      <ICON.Close fill="white" width={20} height={20} />
                    }
                    iconRightOnPress={() => setShowAvailableCask(false)}
                  >
                    <TableWithAction
                      columns={[
                        {
                          key: "Distillery",
                          title: "DISTILLERY",
                          flex: 1,
                          center: true,
                        },
                        {
                          key: "Region",
                          title: "REGION",
                          flex: 1,
                          center: true,
                        },
                        {
                          key: "Cask Type",
                          title: "CASK TYPE",
                          flex: 1,
                          center: true,
                        },
                        {
                          key: "Cask number",
                          title: "CASK#",
                          flex: 1,
                          center: true,
                        },
                        { key: "Age", title: "AGE", flex: 1, center: true },
                        {
                          key: "Price",
                          title: "CASK PRICE",
                          flex: 1,
                          center: true,
                        },
                      ]}
                      data={listAvailableCasks}
                      onPressAction={(item, i) => messageAdmin(item)}
                      breakpointToScroll="sm"
                      btnTitle="Request Info"
                    />
                  </Card>
                </Scroll>
              </Col>
            )}
          </Col>
        </Col>
        {height > 600 && width > 1220 && <Footer />}
      </>
    </Col>
  );
};

Casks.routeInfo = {
  title: 'Casks',
  path: '/casks',
};

export default Casks;