import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Image, View, useWindowDimensions } from 'react-native';
import { Col, Row, useDynamicResponsiveValue, Text, Scroll } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Footer, Header, Card, Button, Input, ChatBox, ChartMultiple, TableWithAction, QuickMessage, Table, CaskIOwn, Breadcrumbs } from "components";
import { BlurView } from 'expo-blur';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import _ from 'lodash';
import { saveAs } from 'file-saver';

let XLSX;

const YourCasks: IScreen = () => {
  const [{ email, name }] = Store.User.createStore();
  const [{ }, mApi] = Store.Message.createStore();
  const [{ listNews, listPages }, iApi] = Store.Info.createStore();

  const [{ listDocs, listAvailableCasks, listClients }, cApi] = Store.Client.createStore();

  const { navigate } = useNavFunc();
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const BLUR_WIDTH = rV({ xs: (width - 80) / 2, md: (width - 160) / 3 });
  const BLUR_HEIGHT = rV({ xs: (width - 80) / 2, md: (width - 160) * 0.6 / 3 });
  const ITEM_WIDTH = rV({ xs: (width - 50), lg: (width - 110) / 3 });
  const ROW_MARGIN = rV({ xs: 25, md: 35, lg: 45 });
  const COLUMN_MARGIN = rV({ xs: 5, lg: 10 });

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  useEffect(() => {
    iApi.getListEvents();
    iApi.getListNews();
    cApi.getListClients();
    cApi.getListAvailableCasks();
    iApi.getListPages();
    cApi.getListDocs();
  }, []);

  const chartRef = useRef<{ setActiveTab: Function }>();

  const pageData = listPages.find(val => val.Title === 'Account Summary');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const myAM = listClients.length > 0 ? listClients[0]['Account Manager'] : '';

  const messageAdmin = async (item) => {
    // console.log('item', item);
    // console.log('myAM', myAM);
    // { Region: 'Highlands', Cask Type: 'Barel', Age: 5, Price: '5-10' }
    const [res, err] = await mApi.sendMessage({
      subject: 'Request cask info',
      from: email,
      message: `${name} (${email}) has requested info about this cask: Region ${item.Region} | type ${item['Cask Type']} | Age ${item.Age} | Price ${item.Price}`,
      am: myAM,
    });
    alert('Thank you, your request has been sent');
  }

  const ScreenContainer = React.Fragment;

  const { portfolioValue } = (() => {
    if (listClients.length === 0 || !email) return { portfolioValue: 0 };
    const filterClient = listClients.filter(val => val.Email === email);
    // console.log('filterClient', filterClient);
    // if (filterClient.length > 0) return {
    //   portfolioValue: +filterClient[0]['Portfolio'],
    // }
    if (filterClient.length > 0) return filterClient.reduce((acc, val) => {
      return {
        portfolioValue: acc.portfolioValue + +val['Portfolio'],
      }
    }, { portfolioValue: 0 });
    return {
      portfolioValue: 0,
    };
  })();

  const numberOfRegions = _.uniqWith(listClients.filter(val => val.Email === email && !!val['Region']), _.isEqual).length;
  const listDistilleries = _.uniqWith(listClients.filter(val => val.Email === email && !!val['Distillery']).map(val => val.Distillery), _.isEqual);

  const { myCommision } = cApi.computeMyComission();

  const filterClient = listClients.filter(val => val.Email === email).sort((a, b) => a['Region'] < b['Region'] ? -1 : 1);

  const exportExcel = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet([
      {
        Name: 'Portfolio',
        Value: VarHelper.money(portfolioValue),
      },
      {
        Name: 'No. of casks I own',
        Value: filterClient.length,
      },
      {
        Name: 'Your Distilleries',
        Value: listDistilleries.length,
      },
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Account Summary");

    const yourCasks = filterClient.map(val => ({
      ...val,
      ['AYS']: TimeHelper.format(val['AYS']),
      ['Cask price']: VarHelper.money(val['Unit Price']),
      ['Portfolio']: VarHelper.money(val['Portfolio']),
      ['Purchased Date']: TimeHelper.format(val['Purchased Date']),
      ['Cask No']: val['Cask NO'],
      ['RLA']: val['OLA/RLA'],
      // ['KYC']: val['KYC'],
      'Recommended Re-gauge Date': TimeHelper.format(val['Recommended Re-gauge Date']),
    }))
    var ws2 = XLSX.utils.json_to_sheet(yourCasks);
    XLSX.utils.book_append_sheet(wb, ws2, "Your Casks");

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `export-account-summary-${new Date().getTime()}.xlsx`);
  };

  const renderRow = (arrayOfComp) => {
    return (
      <Row
        style={{ flexWrap: 'wrap' }}
        marginBottom={10}
        marginTop20 stretch={rV({ xs: undefined, md: true })}
        marginHorizontal={ROW_MARGIN}
      >
        {arrayOfComp}
      </Row>
    )
  }

  const renderCasksIOwn = () => {
    return (
      <Card
        title={"Casks I own"}
        width={rV({ xs: ITEM_WIDTH - 20, lg: ITEM_WIDTH * 3 - 10 })}
        marginHorizontal={COLUMN_MARGIN} marginBottom10 iconRigth={<ICON.IconPie />}
      >
        <CaskIOwn ref={chartRef} />
      </Card>
    )
  }

  const renderYourCask = () => {
    return (
      <Col width={rV({ xs: ITEM_WIDTH - 20, lg: ITEM_WIDTH * 3 - 10 })}
        marginHorizontal={COLUMN_MARGIN} marginBottom10>
        {/* <Card title={"Your Casks"}>
          
        </Card> */}
        {!!filterClient && filterClient.length === 0 ? (
          <Col>
            <Text body1>No record found</Text>
          </Col>
        ) : (
          <Table
            // columns={[
            //   // 'Spirit', 'Distillery', 'Region', 'AYS', 'Cask NO', 'Cask Type',
            //   // 'OLA/RLA', 'ABV', 'Cask price',
            //   // 'Location',
            //   // 'Purchased Date',
            //   // 'Recommended Re-gauge',
            //   'Cask NO',
            //   'Spirit',
            //   'Purchase Date',
            //   'Distillery',
            //   'OLA',
            //   'RLA',
            //   'ABV',
            //   'Location',
            // ].map(val => ({
            //   key: val, title: val, padding: 5
            // })).map(val => ({ ...val, center: true, width: 150, height: 40, padding: 10 }))}
            columns={[
              { key: 'Spirit', title: 'Spirit', flex: 1, center: true },
              { key: 'Distillery', title: 'Distillery', flex: 1, center: true },
              { key: 'Region', title: 'Region', flex: 1, center: true },
              { key: 'AYS', title: 'AYS', flex: 1, center: true, tooltip: 'Age of Youngest Spirit' },
              { key: 'Cask Type', title: 'Cask Type', flex: 1, center: true },
              { key: 'OLA', title: 'OLA', flex: 1, center: true, tooltip: 'Original Litres of Alcohol' },
              { key: 'RLA', title: 'RLA', flex: 1, center: true, tooltip: 'Regauged Litres of Alcohol' },
              { key: 'ABV', title: 'ABV', flex: 1, center: true, tooltip: 'Alcohol By Volume' },
              { key: 'Portfolio', title: 'Price', flex: 1, center: true },
              { key: 'Purchase Date', title: 'Purchase Date', flex: 1, center: true },
              { key: 'Cask NO', title: 'Cask NO', flex: 1, center: true, showInDetail: true },
              { key: 'Location', title: 'Location', flex: 1, center: true, showInDetail: true },
              { key: 'Age', title: 'Age', showInDetail: true },
              { key: 'Cask Price', title: 'Cask Price', showInDetail: true },
              { key: 'Brand Owner', title: 'Brand Owner', showInDetail: true },
              { key: 'Recommended Re-gauge Date', title: 'Recommended Re-gauge Date', showInDetail: true },
              { key: 'Invested', title: 'Invested', showInDetail: true },
              { key: 'Investment Tier', title: 'Investment Tier', showInDetail: true },
              { key: 'AMC Renewal', title: 'AMC Renewal', showInDetail: true },
            ]}
            withoutNumberColumn
            data={filterClient.map(val => ({
              ...val,
              // ['Spirit']: () => (
              //   <Col>
              //     <Text>{val['Spirit']}</Text>
              //     <Text bold color="#AD8555">£{val['Portfolio']}</Text>
              //   </Col>
              // ),
              ['Spirit']: val['Spirit'],
              ['AYS']: val['AYS'],
              ['Cask Price']: VarHelper.money(val['Portfolio']),
              ['Portfolio']: VarHelper.money(val['Portfolio']),
              ['Invested']: VarHelper.money(val['Invested']),
              ['Purchase Date']: val['Purchase Date'],
              ['Cask NO']: val['Cask No'],
              ['OLA/RLA']: val['OLA/RLA'],
              // ['KYC']: () => (
              //   <a href={val['KYC Link']}>{val['KYC']}</a>
              // ),
              ['Location']: val['Location'],
              'Recommended Re-gauge Date': val['Recommended Re-gauge Date'],
            }))}
            // breakpointToScroll="all"
          />
        )}
      </Col>
    )
  }

  const renderAvailableCasks = () => {
    return (
      <Col width={rV({ xs: ITEM_WIDTH - 20, lg: ITEM_WIDTH * 3 - 10 })}
        marginHorizontal={COLUMN_MARGIN} marginBottom10>
        <Card title={"Available Casks - Subject to being sold"} height100p>
          <TableWithAction
            titleBlackBg
            columns={[
              { key: 'Distillery', title: 'DISTILLERY', flex: 1, center: true },
              { key: 'Region', title: 'REGION', flex: 1, center: true },
              { key: 'Cask Type', title: 'CASK TYPE', flex: 1, center: true },
              { key: 'Cask number', title: 'CASK#', flex: 1, center: true },
              { key: 'Age', title: 'AGE', flex: 1, center: true },
              { key: 'Price', title: 'CASK PRICE', flex: 1, center: true },
            ]}
            data={listAvailableCasks}
            onPressAction={(item, i) => messageAdmin(item)}
            breakpointToScroll="sm"
            btnTitle="Request Info"
          />
        </Card>

      </Col>
    )
  }

  const scrollToChart = (activeTab) => {
    window.scrollTo({ left: 0, top: 319 });
    if (chartRef.current?.setActiveTab) chartRef.current?.setActiveTab(activeTab);
  }

  const renderSquare = ({ onPress, image, title, value }) => {
    return (
      <Col onPress={onPress} overflowH marginHorizontal={rV({ xs: 5, md: 10 })} marginBottom10>
        <Image source={image} style={{ width: BLUR_WIDTH, height: BLUR_HEIGHT, resizeMode: 'cover' }} />
        <Col absoluteFill>
          <Text height40 marginTop={BLUR_HEIGHT / 3.5} center bold color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
            {title}
          </Text>
          <Row minHeight40 justifyContent="center">
            <Text center bold color={COLOR.WHITE} fontSize={String(value).length <= 5 ? 50 : 30}>
              {value}
            </Text>
          </Row>
        </Col>
      </Col>
    );
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer>
        <Header />
        <Col flex1>
          <Row justifyContent="space-between">
            <Breadcrumbs
              title="Your Casks"
              style={{
                marginLeft: ROW_MARGIN + COLUMN_MARGIN,
              }}
            />
            <Col marginTop20 marginRight={ROW_MARGIN + COLUMN_MARGIN} onPress={exportExcel}>
              <Text fontSize={15} letterSpacing={0.3}>Export Data</Text>
            </Col>
          </Row>

          {renderRow([
            renderYourCask(),
          ])}
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

YourCasks.routeInfo = {
  title: 'Your Casks',
  path: '/your-casks',
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  blurredImage: {
    width: 192,
    height: 192,
  },
  nonBlurredContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default YourCasks;